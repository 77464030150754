// Constantes
import { URL_APP_MENU } from '@/constants'
// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import CardContainer from '@/components/ui/CardContainer'
import ShareLink from '@/components/ui/ShareLink'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import takeAwayMixin from '@/addons/takeAway/mixins/takeAwayMixin'
// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ShareMenuLink',
  components: {
    CardContainer,
    ShareLink,
    VuetifyToolBar
  },
  mixins: [addonsMixin, takeAwayMixin],
  computed: {
    ...mapGetters('place', ['placeData']),
    ...mapGetters('takeAway', ['takeAwayHasMenu']),
    /**
     * Mostrar enlace de TAD
     *
     * @return {Boolean}
     */
    showTADMenu() {
      return this.hasTakeAwayAddon && this.takeAwayHasMenu
    },
    /**
     * Obtenemos la URL del establecimiento
     *
     * @return {String}
     */
    urlMenu() {
      return `${URL_APP_MENU}${this.placeData.url}`
    },
    /**
     * Obtenemos la URL del servicio de TAD
     *
     * @return {String}
     */
    urlTAD() {
      return `${URL_APP_MENU}tad/${this.placeData.url}`
    },
    /**
     * Obtenemos la URL del QR del establecimiento
     *
     * @return {String}
     */
    urlMenuQr() {
      return this.placeData.qrCode || ''
    },
    /**
     * Obtenemos la URL del QR del TAD
     *
     * @return {String}
     */
    urlTADQr() {
      return this.placeData.takeAwayQrCode || ''
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('place', ['getPlace']),
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async getEveryNeededData() {
      try {
        // Obtenemos datos del establecimiento
        // TODO - tras el registro, las funciones de Google crean el QR, pero son tan lentas
        // que cuando accedemos aun no poseemos dicho QR, por lo que se debe consultar de nuevo
        // a la carga de este componente
        await this.getPlace(this.placeData.id)
      } catch (error) {
        // show error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      }
    }
  }
}
